import React, { useEffect } from "react";
import { aboutImg1 } from "../../../assets";

const Feature4 = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <section
      id="about"
      class="bg-[#4B0082] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]"
    >
      <div class="container">
        <div class="wow fadeInUp bg-white" data-wow-delay=".2s">
          <div class="-mx-4 flex flex-wrap">
            <div class="w-full px-4">
              <div class="items-center justify-between overflow-hidden border lg:flex">
                <div class="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[565px] lg:py-9 lg:px-16 xl:max-w-[640px] xl:p-[70px]">
                  <span class="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-white">
                    Services
                  </span>
                  <h2 class="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                    {/* We have transformed our life and can do the same for you…. */}
                    {/* Welcome to <br />{" "}
                    <span className="text-[#4B0082] ml-2 font-mono  font-extrabold">
                      Mind transformative solutions
                    </span> */}
                    In Person Consultancy
                  </h2>
                  <p class="mb-9 text-base leading-relaxed text-body-color">
                    "Step into a world of personalized expertise with our
                    In-Person Consultancy Services, where face-to-face
                    interactions bring a tailored and immersive consulting
                    experience to life. Our seasoned professionals are dedicated
                    to providing you with hands-on guidance, strategic insights,
                    and actionable solutions in a comfortable and confidential
                    setting. Experience the difference of in-person consultancy
                    – where expert advice meets a personalized, face-to-face
                    connection. Connect with us and let's embark on a journey of
                    strategic planning, growth, and success together."
                  </p>
                </div>
                <div class="text-center">
                  <div class="relative z-10 inline-block">
                    <img
                      src={aboutImg1}
                      alt="image"
                      class="mx-auto lg:ml-auto px-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature4;
