import React, { useEffect } from "react";
import { onlineTraining } from "../../../assets";

const Feature1 = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      id="about"
      class="bg-[#4B0082] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]"
    >
      <div class="container">
        <div class="wow fadeInUp bg-white" data-wow-delay=".2s">
          <div class="-mx-4 flex flex-wrap">
            <div class="w-full px-4">
              <div class="items-center justify-between overflow-hidden border lg:flex">
                <div class="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[565px] lg:py-9 lg:px-16 xl:max-w-[640px] xl:p-[70px]">
                  <span class="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-white">
                    Services
                  </span>
                  <h2 class="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                    {/* We have transformed our life and can do the same for you…. */}
                    {/* Welcome to <br />{" "} */}
                    Online Consultancy
                    {/* <span className="text-[#4B0082] ml-2 font-mono  font-extrabold">
                      Mind transformative solutions
                    </span> */}
                  </h2>
                  <p class="mb-9 text-base leading-relaxed text-body-color">
                    "Welcome to our Online Consultancy Services, where expertise
                    meets convenience in the digital age. Embrace the
                    flexibility of scheduling consultations that fit seamlessly
                    into your busy lifestyle. Our online platform ensures a
                    secure and confidential environment for you to discuss your
                    concerns and receive expert insights. Say goodbye to
                    geographical constraints and time limitations – our virtual
                    consultancy services break down barriers, making knowledge
                    and support accessible to you anytime, anywhere. our online
                    consultancy services are designed to meet your unique
                    requirements. Take the first step towards informed
                    decision-making and success by connecting with us today."
                  </p>
                  {/* <p class="mb-9 text-base leading-relaxed text-body-color">
                    <h3 className="font-bold text-dark">Our Mission:</h3>{" "}
                    Elevating Success Through Emotional Intelligence We
                    specialize in providing cutting-edge consultancy services
                    that revolve around emotional intelligence. Our mission is
                    to empower individuals, teams, and organizations to unlock
                    their full potential by harnessing the power of emotions. We
                    understand that success is not only about technical
                    expertise but also about mastering the art of emotional
                    agility.
                  </p> */}
                </div>
                <div class="text-center">
                  <div class="relative z-10 inline-block">
                    <img
                      src={onlineTraining}
                      alt="image"
                      class="mx-auto lg:ml-auto px-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature1;
