// import React from "react";
// import { profile } from "../../assets";

// // .about {
// //   display: flex;
// //   justify-content: center;
// //   align-items: center;
// //   gap: 2rem;
// //   background: var(--second-bg-color);
// // }

// const Team = () => {
//   return (
//     <section
//       className=" gap-8 bg-white pt-20  pb-10 lg:pt-[120px] lg:pb-20"
//       id="about"
//     >
//       <div className="container flex justify-center items-center">
//         <div className="w-[800px] pr-3">
//           <img src={profile} alt="" />
//         </div>

//         <div>
//           <h2 className="text-left leading-6 mb-5 text-base font-semibold text-black">
//             About <span>Me</span>
//           </h2>
//           <h3 className="text-[35px] font-semibold text-black">
//             Public Speaker!
//           </h3>
//           <p className="text-2xl mt-8 mb-12">
//             I am Bhargavi Kulkarni, as always wanted to be a Public Speaker,
//             will be pursuing my career in Training and Counseling, as it’s my
//             dad ‘s dream and few years ago it became my dream as well. I am a
//             book and nature lover, love to travel and write poems in Hindi
//             language. I am also very passionate about face reading.
//           </p>
//           <a href="" class="btn">
//             Read More
//           </a>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default Team;

import React from "react";
import { profile } from "../../assets";

const Team = () => {
  return (
    <section id="team" class="pt-20  pb-10 lg:pt-[120px] lg:pb-20">
      <div class="container">
        <div class="-mx-4 flex flex-wrap">
          <div class="w-full px-4">
            <div class="mx-auto mb-[60px] max-w-[620px] text-center">
              {/* <span class="mb-2 block text-lg font-semibold text-primary">
                Our Team
              </span> */}
              <h2 class="mb-4 text-3xl font-bold text-primary sm:text-4xl md:text-[42px]">
                Consultancy from
              </h2>
              {/* <p class="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                We work together to grow together.
              </p> */}
            </div>
          </div>
        </div>

        <div class="-mx-4 flex flex-wrap justify-center">
          {/* <div class="w-full px-4 sm:w-1/2 lg:w-1/4">
            <div class="wow fadeInUp mb-10" data-wow-delay=".1s">
              <div class="h-170px] relative z-10 mx-auto mb-6 w-[170px] rounded-full">
                <img
                  src="assets/images/team/team-01.png"
                  alt="image"
                  class="w-full rounded-full"
                />
                <span class="absolute top-0 left-0 z-[-1]"></span>
                <span class="absolute right-0 bottom-0">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.5 21.5L0.505701 21.5C0.767606 10.023 10.023 0.767604 21.5 0.505697L21.5 21.5Z"
                      stroke="#13C296"
                    />
                  </svg>
                </span>
              </div>
              <div class="text-center">
                <h4 class="mb-2 text-lg font-medium text-dark">
                  Adveen Desuza
                </h4>
                <p class="mb-5 text-sm font-medium text-body-color">
                  UI Designer
                </p>
                <div class="flex items-center justify-center">
                  <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="10"
                      height="18"
                      viewBox="0 0 10 18"
                      class="fill-current"
                    >
                      <path d="M9.29878 7.2H7.74898H7.19548V6.61935V4.81936V4.23871H7.74898H8.91133C9.21575 4.23871 9.46483 4.00645 9.46483 3.65806V0.580645C9.46483 0.26129 9.24343 0 8.91133 0H6.89106C4.70474 0 3.18262 1.62581 3.18262 4.03548V6.56129V7.14194H2.62912H0.747223C0.359774 7.14194 0 7.46129 0 7.92581V10.0161C0 10.4226 0.304424 10.8 0.747223 10.8H2.57377H3.12727V11.3806V17.2161C3.12727 17.6226 3.43169 18 3.87449 18H6.47593C6.64198 18 6.78036 17.9129 6.89106 17.7968C7.00176 17.6806 7.08478 17.4774 7.08478 17.3032V11.4097V10.829H7.66596H8.91133C9.2711 10.829 9.54785 10.5968 9.6032 10.2484V10.2194V10.1903L9.99065 8.1871C10.0183 7.98387 9.99065 7.75161 9.8246 7.51935C9.76925 7.37419 9.52018 7.22903 9.29878 7.2Z" />
                    </svg>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      class="fill-current"
                    >
                      <path d="M15.9968 2.41096L17.1 1.09589C17.4194 0.739726 17.5065 0.465753 17.5355 0.328767C16.6645 0.821918 15.8516 0.986301 15.329 0.986301H15.1258L15.0097 0.876712C14.3129 0.30137 13.4419 0 12.5129 0C10.4806 0 8.88387 1.58904 8.88387 3.42466C8.88387 3.53425 8.88387 3.69863 8.9129 3.80822L9 4.35616L8.39032 4.32877C4.67419 4.21918 1.62581 1.20548 1.13226 0.684932C0.319355 2.05479 0.783871 3.36986 1.27742 4.19178L2.26452 5.72603L0.696774 4.90411C0.725806 6.05479 1.19032 6.9589 2.09032 7.61644L2.87419 8.16438L2.09032 8.46575C2.58387 9.86301 3.6871 10.4384 4.5 10.6575L5.57419 10.9315L4.55806 11.589C2.93226 12.6849 0.9 12.6027 0 12.5205C1.82903 13.726 4.00645 14 5.51613 14C6.64839 14 7.49032 13.8904 7.69355 13.8082C15.8226 12 16.2 5.15068 16.2 3.78082V3.58904L16.3742 3.47945C17.3613 2.60274 17.7677 2.13699 18 1.86301C17.9129 1.89041 17.7968 1.94521 17.6806 1.9726L15.9968 2.41096Z" />
                    </svg>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      class="fill-current"
                    >
                      <path d="M8.90245 12.1939C10.7363 12.1939 12.2229 10.7073 12.2229 8.87352C12.2229 7.0397 10.7363 5.5531 8.90245 5.5531C7.06863 5.5531 5.58203 7.0397 5.58203 8.87352C5.58203 10.7073 7.06863 12.1939 8.90245 12.1939Z" />
                      <path d="M12.5088 0H5.23824C2.34719 0 0 2.34719 0 5.23824V12.4516C0 15.3999 2.34719 17.7471 5.23824 17.7471H12.4516C15.3999 17.7471 17.7471 15.3999 17.7471 12.5088V5.23824C17.7471 2.34719 15.3999 0 12.5088 0ZM8.90215 13.2244C6.46909 13.2244 4.55126 11.2493 4.55126 8.87353C4.55126 6.49771 6.49771 4.52264 8.90215 4.52264C11.278 4.52264 13.2244 6.49771 13.2244 8.87353C13.2244 11.2493 11.3066 13.2244 8.90215 13.2244ZM14.9133 4.92338C14.627 5.23824 14.1976 5.40999 13.711 5.40999C13.2817 5.40999 12.8523 5.23824 12.5088 4.92338C12.1939 4.60851 12.0222 4.20777 12.0222 3.72116C12.0222 3.23454 12.1939 2.86243 12.5088 2.51894C12.8237 2.17545 13.2244 2.0037 13.711 2.0037C14.1404 2.0037 14.5984 2.17545 14.9133 2.49031C15.1995 2.86243 15.3999 3.29179 15.3999 3.74978C15.3712 4.20777 15.1995 4.60851 14.9133 4.92338Z" />
                      <path d="M13.7397 3.03418C13.3676 3.03418 13.0527 3.34905 13.0527 3.72116C13.0527 4.09328 13.3676 4.40815 13.7397 4.40815C14.1118 4.40815 14.4267 4.09328 14.4267 3.72116C14.4267 3.34905 14.1405 3.03418 13.7397 3.03418Z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div class="w-full px-4 sm:w-1/2 lg:w-1/4">
            <div class="wow fadeInUp mb-10" data-wow-delay=".15s">
              <div class="h-170px] relative z-10 mx-auto mb-6 w-[170px] rounded-full">
                <img
                  src="assets/images/team/team-02.png"
                  alt="image"
                  class="w-full rounded-full"
                />
                <span class="absolute top-0 left-0 z-[-1]"></span>
                <span class="absolute right-0 bottom-0">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.5 21.5L0.505701 21.5C0.767606 10.023 10.023 0.767604 21.5 0.505697L21.5 21.5Z"
                      stroke="#13C296"
                    />
                  </svg>
                </span>
              </div>
              <div class="text-center">
                <h4 class="mb-2 text-lg font-medium text-dark">Jezmin uniya</h4>
                <p class="mb-5 text-sm font-medium text-body-color">
                  Product Designer
                </p>
                <div class="flex items-center justify-center">
                  <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="10"
                      height="18"
                      viewBox="0 0 10 18"
                      class="fill-current"
                    >
                      <path d="M9.29878 7.2H7.74898H7.19548V6.61935V4.81936V4.23871H7.74898H8.91133C9.21575 4.23871 9.46483 4.00645 9.46483 3.65806V0.580645C9.46483 0.26129 9.24343 0 8.91133 0H6.89106C4.70474 0 3.18262 1.62581 3.18262 4.03548V6.56129V7.14194H2.62912H0.747223C0.359774 7.14194 0 7.46129 0 7.92581V10.0161C0 10.4226 0.304424 10.8 0.747223 10.8H2.57377H3.12727V11.3806V17.2161C3.12727 17.6226 3.43169 18 3.87449 18H6.47593C6.64198 18 6.78036 17.9129 6.89106 17.7968C7.00176 17.6806 7.08478 17.4774 7.08478 17.3032V11.4097V10.829H7.66596H8.91133C9.2711 10.829 9.54785 10.5968 9.6032 10.2484V10.2194V10.1903L9.99065 8.1871C10.0183 7.98387 9.99065 7.75161 9.8246 7.51935C9.76925 7.37419 9.52018 7.22903 9.29878 7.2Z" />
                    </svg>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      class="fill-current"
                    >
                      <path d="M15.9968 2.41096L17.1 1.09589C17.4194 0.739726 17.5065 0.465753 17.5355 0.328767C16.6645 0.821918 15.8516 0.986301 15.329 0.986301H15.1258L15.0097 0.876712C14.3129 0.30137 13.4419 0 12.5129 0C10.4806 0 8.88387 1.58904 8.88387 3.42466C8.88387 3.53425 8.88387 3.69863 8.9129 3.80822L9 4.35616L8.39032 4.32877C4.67419 4.21918 1.62581 1.20548 1.13226 0.684932C0.319355 2.05479 0.783871 3.36986 1.27742 4.19178L2.26452 5.72603L0.696774 4.90411C0.725806 6.05479 1.19032 6.9589 2.09032 7.61644L2.87419 8.16438L2.09032 8.46575C2.58387 9.86301 3.6871 10.4384 4.5 10.6575L5.57419 10.9315L4.55806 11.589C2.93226 12.6849 0.9 12.6027 0 12.5205C1.82903 13.726 4.00645 14 5.51613 14C6.64839 14 7.49032 13.8904 7.69355 13.8082C15.8226 12 16.2 5.15068 16.2 3.78082V3.58904L16.3742 3.47945C17.3613 2.60274 17.7677 2.13699 18 1.86301C17.9129 1.89041 17.7968 1.94521 17.6806 1.9726L15.9968 2.41096Z" />
                    </svg>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      class="fill-current"
                    >
                      <path d="M8.90245 12.1939C10.7363 12.1939 12.2229 10.7073 12.2229 8.87352C12.2229 7.0397 10.7363 5.5531 8.90245 5.5531C7.06863 5.5531 5.58203 7.0397 5.58203 8.87352C5.58203 10.7073 7.06863 12.1939 8.90245 12.1939Z" />
                      <path d="M12.5088 0H5.23824C2.34719 0 0 2.34719 0 5.23824V12.4516C0 15.3999 2.34719 17.7471 5.23824 17.7471H12.4516C15.3999 17.7471 17.7471 15.3999 17.7471 12.5088V5.23824C17.7471 2.34719 15.3999 0 12.5088 0ZM8.90215 13.2244C6.46909 13.2244 4.55126 11.2493 4.55126 8.87353C4.55126 6.49771 6.49771 4.52264 8.90215 4.52264C11.278 4.52264 13.2244 6.49771 13.2244 8.87353C13.2244 11.2493 11.3066 13.2244 8.90215 13.2244ZM14.9133 4.92338C14.627 5.23824 14.1976 5.40999 13.711 5.40999C13.2817 5.40999 12.8523 5.23824 12.5088 4.92338C12.1939 4.60851 12.0222 4.20777 12.0222 3.72116C12.0222 3.23454 12.1939 2.86243 12.5088 2.51894C12.8237 2.17545 13.2244 2.0037 13.711 2.0037C14.1404 2.0037 14.5984 2.17545 14.9133 2.49031C15.1995 2.86243 15.3999 3.29179 15.3999 3.74978C15.3712 4.20777 15.1995 4.60851 14.9133 4.92338Z" />
                      <path d="M13.7397 3.03418C13.3676 3.03418 13.0527 3.34905 13.0527 3.72116C13.0527 4.09328 13.3676 4.40815 13.7397 4.40815C14.1118 4.40815 14.4267 4.09328 14.4267 3.72116C14.4267 3.34905 14.1405 3.03418 13.7397 3.03418Z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          {/* <div class="w-full px-4 sm:w-1/2 lg:w-1/4">
            <div class="wow fadeInUp mb-10" data-wow-delay=".2s">
              <div class="h-170px] relative z-10 mx-auto mb-6 w-[170px] rounded-full">
                <img
                  src="assets/images/team/team-03.png"
                  alt="image"
                  class="w-full rounded-full"
                />
                <span class="absolute top-0 left-0 z-[-1]"></span>
                <span class="absolute right-0 bottom-0">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.5 21.5L0.505701 21.5C0.767606 10.023 10.023 0.767604 21.5 0.505697L21.5 21.5Z"
                      stroke="#13C296"
                    />
                  </svg>
                </span>
              </div>
              <div class="text-center">
                <h4 class="mb-2 text-lg font-medium text-dark">
                  Andrieo Gloree
                </h4>
                <p class="mb-5 text-sm font-medium text-body-color">
                  App Developer
                </p>
                <div class="flex items-center justify-center">
                  <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="10"
                      height="18"
                      viewBox="0 0 10 18"
                      class="fill-current"
                    >
                      <path d="M9.29878 7.2H7.74898H7.19548V6.61935V4.81936V4.23871H7.74898H8.91133C9.21575 4.23871 9.46483 4.00645 9.46483 3.65806V0.580645C9.46483 0.26129 9.24343 0 8.91133 0H6.89106C4.70474 0 3.18262 1.62581 3.18262 4.03548V6.56129V7.14194H2.62912H0.747223C0.359774 7.14194 0 7.46129 0 7.92581V10.0161C0 10.4226 0.304424 10.8 0.747223 10.8H2.57377H3.12727V11.3806V17.2161C3.12727 17.6226 3.43169 18 3.87449 18H6.47593C6.64198 18 6.78036 17.9129 6.89106 17.7968C7.00176 17.6806 7.08478 17.4774 7.08478 17.3032V11.4097V10.829H7.66596H8.91133C9.2711 10.829 9.54785 10.5968 9.6032 10.2484V10.2194V10.1903L9.99065 8.1871C10.0183 7.98387 9.99065 7.75161 9.8246 7.51935C9.76925 7.37419 9.52018 7.22903 9.29878 7.2Z" />
                    </svg>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      class="fill-current"
                    >
                      <path d="M15.9968 2.41096L17.1 1.09589C17.4194 0.739726 17.5065 0.465753 17.5355 0.328767C16.6645 0.821918 15.8516 0.986301 15.329 0.986301H15.1258L15.0097 0.876712C14.3129 0.30137 13.4419 0 12.5129 0C10.4806 0 8.88387 1.58904 8.88387 3.42466C8.88387 3.53425 8.88387 3.69863 8.9129 3.80822L9 4.35616L8.39032 4.32877C4.67419 4.21918 1.62581 1.20548 1.13226 0.684932C0.319355 2.05479 0.783871 3.36986 1.27742 4.19178L2.26452 5.72603L0.696774 4.90411C0.725806 6.05479 1.19032 6.9589 2.09032 7.61644L2.87419 8.16438L2.09032 8.46575C2.58387 9.86301 3.6871 10.4384 4.5 10.6575L5.57419 10.9315L4.55806 11.589C2.93226 12.6849 0.9 12.6027 0 12.5205C1.82903 13.726 4.00645 14 5.51613 14C6.64839 14 7.49032 13.8904 7.69355 13.8082C15.8226 12 16.2 5.15068 16.2 3.78082V3.58904L16.3742 3.47945C17.3613 2.60274 17.7677 2.13699 18 1.86301C17.9129 1.89041 17.7968 1.94521 17.6806 1.9726L15.9968 2.41096Z" />
                    </svg>
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      class="fill-current"
                    >
                      <path d="M8.90245 12.1939C10.7363 12.1939 12.2229 10.7073 12.2229 8.87352C12.2229 7.0397 10.7363 5.5531 8.90245 5.5531C7.06863 5.5531 5.58203 7.0397 5.58203 8.87352C5.58203 10.7073 7.06863 12.1939 8.90245 12.1939Z" />
                      <path d="M12.5088 0H5.23824C2.34719 0 0 2.34719 0 5.23824V12.4516C0 15.3999 2.34719 17.7471 5.23824 17.7471H12.4516C15.3999 17.7471 17.7471 15.3999 17.7471 12.5088V5.23824C17.7471 2.34719 15.3999 0 12.5088 0ZM8.90215 13.2244C6.46909 13.2244 4.55126 11.2493 4.55126 8.87353C4.55126 6.49771 6.49771 4.52264 8.90215 4.52264C11.278 4.52264 13.2244 6.49771 13.2244 8.87353C13.2244 11.2493 11.3066 13.2244 8.90215 13.2244ZM14.9133 4.92338C14.627 5.23824 14.1976 5.40999 13.711 5.40999C13.2817 5.40999 12.8523 5.23824 12.5088 4.92338C12.1939 4.60851 12.0222 4.20777 12.0222 3.72116C12.0222 3.23454 12.1939 2.86243 12.5088 2.51894C12.8237 2.17545 13.2244 2.0037 13.711 2.0037C14.1404 2.0037 14.5984 2.17545 14.9133 2.49031C15.1995 2.86243 15.3999 3.29179 15.3999 3.74978C15.3712 4.20777 15.1995 4.60851 14.9133 4.92338Z" />
                      <path d="M13.7397 3.03418C13.3676 3.03418 13.0527 3.34905 13.0527 3.72116C13.0527 4.09328 13.3676 4.40815 13.7397 4.40815C14.1118 4.40815 14.4267 4.09328 14.4267 3.72116C14.4267 3.34905 14.1405 3.03418 13.7397 3.03418Z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div> */}
          <div class="w-full px-4 sm:w-1/2 lg:w-1/4">
            <div class="wow fadeInUp mb-10" data-wow-delay=".25s">
              <div class="h-170px] relative z-10 mx-auto mb-6 w-[170px] rounded-full">
                <img
                  src={profile}
                  alt="image"
                  class="w-[200px] h-[160px] rounded-full"
                />
                <span class="absolute top-0 left-0 z-[-1]"></span>
                {/* <span class="absolute right-0 bottom-0">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M21.5 21.5L0.505701 21.5C0.767606 10.023 10.023 0.767604 21.5 0.505697L21.5 21.5Z"
                      stroke="#13C296"
                    />
                  </svg>
                </span> */}
              </div>
              <div class="text-center">
                <h4 class="mb-2 text-lg font-medium text-dark">
                  Bhargavi Kulkarni
                </h4>
                <p class="mb-5 text-sm font-medium text-body-color">
                  Consultant <br />
                  Member of National Positive Psychology Association
                </p>
                <div class="flex items-center justify-center">
                  {/* <a
                    href="https://www.linkedin.com/in/bhargavi-kulkarni-a47a90171/"
                    rel="nofollow noopner"
                    target="_blank"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      class="fill-current"
                    >
                      <path d="M18.75 0H1.25C0.558594 0 0 0.558594 0 1.25V18.75C0 19.4414 0.558594 20 1.25 20H18.75C19.4414 20 20 19.4414 20 18.75V1.25C20 0.558594 19.4414 0 18.75 0ZM6.25 18.75H3.125V7.8125H6.25V18.75ZM4.6875 6.25C4.6875 5.67578 4.32344 5.3125 3.75 5.3125C3.17422 5.3125 2.8125 5.67578 2.8125 6.25C2.8125 6.82578 3.17422 7.1875 3.75 7.1875C4.32344 7.1875 4.6875 6.82578 4.6875 6.25ZM18.75 18.75H15.625V12.6562C15.625 11.4586 15.1172 10.6937 14.0109 10.6937C12.8281 10.6937 12.3438 11.4758 12.3438 12.6562V18.75H9.21875V7.8125H12.3438V9.6875H12.375C12.6945 9.05938 13.3625 7.5 15.7812 7.5C18.3727 7.5 18.75 9.30156 18.75 12.1875V18.75Z" />
                    </svg>
                  </a> */}
                  {/* <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="18"
                      height="14"
                      viewBox="0 0 18 14"
                      class="fill-current"
                    >
                      <path d="M15.9968 2.41096L17.1 1.09589C17.4194 0.739726 17.5065 0.465753 17.5355 0.328767C16.6645 0.821918 15.8516 0.986301 15.329 0.986301H15.1258L15.0097 0.876712C14.3129 0.30137 13.4419 0 12.5129 0C10.4806 0 8.88387 1.58904 8.88387 3.42466C8.88387 3.53425 8.88387 3.69863 8.9129 3.80822L9 4.35616L8.39032 4.32877C4.67419 4.21918 1.62581 1.20548 1.13226 0.684932C0.319355 2.05479 0.783871 3.36986 1.27742 4.19178L2.26452 5.72603L0.696774 4.90411C0.725806 6.05479 1.19032 6.9589 2.09032 7.61644L2.87419 8.16438L2.09032 8.46575C2.58387 9.86301 3.6871 10.4384 4.5 10.6575L5.57419 10.9315L4.55806 11.589C2.93226 12.6849 0.9 12.6027 0 12.5205C1.82903 13.726 4.00645 14 5.51613 14C6.64839 14 7.49032 13.8904 7.69355 13.8082C15.8226 12 16.2 5.15068 16.2 3.78082V3.58904L16.3742 3.47945C17.3613 2.60274 17.7677 2.13699 18 1.86301C17.9129 1.89041 17.7968 1.94521 17.6806 1.9726L15.9968 2.41096Z" />
                    </svg>
                  </a> */}
                  {/* <a
                    href="javascript:void(0)"
                    class="mx-2 flex h-8 w-8 items-center justify-center text-[#cdced6] hover:text-primary"
                  >
                    <svg
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      class="fill-current"
                    >
                      <path d="M8.90245 12.1939C10.7363 12.1939 12.2229 10.7073 12.2229 8.87352C12.2229 7.0397 10.7363 5.5531 8.90245 5.5531C7.06863 5.5531 5.58203 7.0397 5.58203 8.87352C5.58203 10.7073 7.06863 12.1939 8.90245 12.1939Z" />
                      <path d="M12.5088 0H5.23824C2.34719 0 0 2.34719 0 5.23824V12.4516C0 15.3999 2.34719 17.7471 5.23824 17.7471H12.4516C15.3999 17.7471 17.7471 15.3999 17.7471 12.5088V5.23824C17.7471 2.34719 15.3999 0 12.5088 0ZM8.90215 13.2244C6.46909 13.2244 4.55126 11.2493 4.55126 8.87353C4.55126 6.49771 6.49771 4.52264 8.90215 4.52264C11.278 4.52264 13.2244 6.49771 13.2244 8.87353C13.2244 11.2493 11.3066 13.2244 8.90215 13.2244ZM14.9133 4.92338C14.627 5.23824 14.1976 5.40999 13.711 5.40999C13.2817 5.40999 12.8523 5.23824 12.5088 4.92338C12.1939 4.60851 12.0222 4.20777 12.0222 3.72116C12.0222 3.23454 12.1939 2.86243 12.5088 2.51894C12.8237 2.17545 13.2244 2.0037 13.711 2.0037C14.1404 2.0037 14.5984 2.17545 14.9133 2.49031C15.1995 2.86243 15.3999 3.29179 15.3999 3.74978C15.3712 4.20777 15.1995 4.60851 14.9133 4.92338Z" />
                      <path d="M13.7397 3.03418C13.3676 3.03418 13.0527 3.34905 13.0527 3.72116C13.0527 4.09328 13.3676 4.40815 13.7397 4.40815C14.1118 4.40815 14.4267 4.09328 14.4267 3.72116C14.4267 3.34905 14.1405 3.03418 13.7397 3.03418Z" />
                    </svg>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
