import React, { useEffect } from "react";
import { aboutImg3 } from "../assets";

const AboutPage = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <section
      id="about"
      className="bg-[#4B0082] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]"
    >
      <div className="container">
        <div className="wow fadeInUp bg-white" data-wow-delay=".2s">
          <div className="-mx-4 flex flex-wrap">
            <div className="w-full lg:flex">
              <div className="w-full lg:w-1/2 py-12 px-7 sm:px-12 md:p-16">
                <span className="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-white">
                  About Us
                </span>
                <h2 className="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                  Welcome to <br />{" "}
                  <span className="text-[#4B0082] ml-2  font-extrabold">
                    Mind Transformative Solutions
                  </span>
                </h2>
                <p className="mb-6 text-base leading-relaxed text-body-color">
                  At Mind transformative solutions, we believe that success in
                  any endeavor, whether personal or professional, is deeply
                  intertwined with emotional intelligence. In a world that
                  constantly evolves, where challenges are diverse and dynamics
                  ever-changing, the ability to understand and navigate emotions
                  is a cornerstone of triumph.
                </p>
                <p className="mb-6 text-base leading-relaxed text-body-color">
                  <h3 className="font-bold text-dark">Our Mission:</h3>{" "}
                  Elevating Success Through Emotional Intelligence. We
                  specialize in providing cutting-edge consultancy services that
                  revolve around emotional intelligence. Our mission is to
                  empower individuals, teams, and organizations to unlock their
                  full potential by harnessing the power of emotions. We
                  understand that success is not only about technical expertise
                  but also about mastering the art of emotional agility.
                </p>
                <p className="mb-6 text-base leading-relaxed text-body-color">
                  <h3 className="font-bold text-dark">
                    What Sets Us Apart: Expertise, Empathy, Excellence
                  </h3>{" "}
                  At Mind transformative solutions, we bring together a team of
                  seasoned professionals who are not only experts in the field
                  but also deeply passionate about the transformative impact of
                  emotional intelligence. Our consultants blend analytical rigor
                  with empathetic insight, tailoring solutions that resonate
                  with the unique challenges faced by our clients.
                </p>
                <p className="mb-6 text-base leading-relaxed text-body-color">
                  <h3 className="font-bold text-dark">
                    Our Services: Customized Solutions for Lasting Impact
                  </h3>{" "}
                  Whether you're an executive looking to enhance leadership
                  skills, a team striving for better collaboration, or an
                  organization seeking cultural transformation, we offer a range
                  of customized services. From workshops and training sessions
                  to one-on-one coaching, our programs are designed to foster
                  emotional intelligence, resulting in lasting positive change.
                </p>
                <p className="mb-6 text-base leading-relaxed text-body-color">
                  <h3 className="font-bold text-dark">
                    Why Emotional Intelligence Matters: The Key to Sustainable
                    Success
                  </h3>{" "}
                  Emotional intelligence is not just a soft skill; it's the
                  bedrock of resilience, effective communication, and harmonious
                  relationships. In an era where success is measured not only by
                  what you know but how well you connect with others, emotional
                  intelligence emerges as the differentiator that propels
                  individuals and organizations towards sustainable success.
                  Join us at Mind transformative solutions, where we go beyond
                  traditional consulting to cultivate emotional intelligence as
                  a catalyst for personal and professional growth. Together,
                  let's embark on a journey of self-discovery, empathy, and
                  unparalleled success. Unlock the power of emotions with Mind
                  transformative solutions.
                </p>
              </div>
              <div className="w-full lg:w-1/2 py-12 px-7 sm:px-12 md:p-16">
                <div className="mb-6">
                  {/* <img
                    src={aboutImg1}
                    alt="image"
                    className="mb-4 w-full h-auto"
                  /> */}
                  {/* <img
                    src={aboutImg2}
                    alt="image"
                    className="mb-4 w-full h-auto"
                  /> */}
                  <img src={aboutImg3} alt="image" className="w-full h-auto" />
                  {/* <img src={aboutImg1} alt="image" className="w-full h-auto" /> */}
                  {/* <img src={aboutImg1} alt="image" className="w-full h-auto" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPage;

// import React from "react";
// import { Link } from "react-router-dom";
// import { aboutImg, aboutImg1 } from "../assets";

// const AboutPage = () => {
//   return (
//     <section
//       id="about"
//       class="bg-[#4B0082] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]"
//     >
//       <div class="container">
//         <div class="wow fadeInUp bg-white" data-wow-delay=".2s">
//           <div class="-mx-4 flex flex-wrap">
//             <div class="w-full px-4">
//               <div class="items-center justify-between overflow-hidden border lg:flex">
//                 <div class="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[565px] lg:py-9 lg:px-16 xl:max-w-[640px] xl:p-[70px]">
//                   <span class="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-white">
//                     About Us
//                   </span>
//                   <h2 class="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
//                     {/* We have transformed our life and can do the same for you…. */}
//                     Welcome to [Your Consultancy Company Name]
//                   </h2>
//                   <p class="mb-9 text-base leading-relaxed text-body-color">
//                     At [Your Consultancy Company Name], we believe that success
//                     in any endeavor, whether personal or professional, is deeply
//                     intertwined with emotional intelligence. In a world that
//                     constantly evolves, where challenges are diverse and
//                     dynamics ever-changing, the ability to understand and
//                     navigate emotions is a cornerstone of triumph.
//                   </p>
//                   <p class="mb-9 text-base leading-relaxed text-body-color">
//                     <h3 className="font-bold text-dark">Our Mission:</h3>{" "}
//                     Elevating Success Through Emotional Intelligence We
//                     specialize in providing cutting-edge consultancy services
//                     that revolve around emotional intelligence. Our mission is
//                     to empower individuals, teams, and organizations to unlock
//                     their full potential by harnessing the power of emotions. We
//                     understand that success is not only about technical
//                     expertise but also about mastering the art of emotional
//                     agility.
//                   </p>
//                   <p class="mb-9 text-base leading-relaxed text-body-color">
//                     <h3 className="font-bold text-dark">
//                       What Sets Us Apart: Expertise, Empathy, Excellence
//                     </h3>{" "}
//                     At [Your Consultancy Company Name], we bring together a team
//                     of seasoned professionals who are not only experts in the
//                     field but also deeply passionate about the transformative
//                     impact of emotional intelligence. Our consultants blend
//                     analytical rigor with empathetic insight, tailoring
//                     solutions that resonate with the unique challenges faced by
//                     our clients.
//                   </p>
//                   <p class="mb-9 text-base leading-relaxed text-body-color">
//                     <h3 className="font-bold text-dark">
//                       Our Services: Customized Solutions for Lasting Impact
//                     </h3>{" "}
//                     Whether you're an executive looking to enhance leadership
//                     skills, a team striving for better collaboration, or an
//                     organization seeking cultural transformation, we offer a
//                     range of customized services. From workshops and training
//                     sessions to one-on-one coaching, our programs are designed
//                     to foster emotional intelligence, resulting in lasting
//                     positive change.
//                   </p>
//                   <p class="mb-9 text-base leading-relaxed text-body-color">
//                     <h3 className="font-bold text-dark">
//                       Why Emotional Intelligence Matters: The Key to Sustainable
//                       Success
//                     </h3>{" "}
//                     Emotional intelligence is not just a soft skill; it's the
//                     bedrock of resilience, effective communication, and
//                     harmonious relationships. In an era where success is
//                     measured not only by what you know but how well you connect
//                     with others, emotional intelligence emerges as the
//                     differentiator that propels individuals and organizations
//                     towards sustainable success. Join us at [Your Consultancy
//                     Company Name], where we go beyond traditional consulting to
//                     cultivate emotional intelligence as a catalyst for personal
//                     and professional growth. Together, let's embark on a journey
//                     of self-discovery, empathy, and unparalleled success. Unlock
//                     the power of emotions with [Your Consultancy Company Name]
//                   </p>
//                 </div>
//                 <div class="text-center">
//                   <div class="relative z-10 inline-block">
//                     <img
//                       src={aboutImg1}
//                       alt="image"
//                       class="mx-auto lg:ml-auto"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default AboutPage;
