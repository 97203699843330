import React from "react";
import { aboutImg, aboutImg1, aboutImg2, aboutImg3 } from "../../assets";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section
      id="about"
      class="bg-[#4B0082] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]"
    >
      <div class="container">
        <div class="wow fadeInUp bg-white" data-wow-delay=".2s">
          <div class="-mx-4 flex flex-wrap">
            <div class="w-full px-4">
              <div class="items-center justify-between overflow-hidden border lg:flex">
                <div class="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[565px] lg:py-9 lg:px-16 xl:max-w-[640px] xl:p-[70px]">
                  <span class="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-white">
                    About Us
                  </span>
                  <h2 class="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                    {/* We have transformed our life and can do the same for you…. */}
                    Welcome to <br />{" "}
                    <span className="text-[#4B0082] ml-2  font-extrabold">
                      Mind Transformative Solutions
                    </span>
                  </h2>
                  <p class="mb-9 text-base leading-relaxed text-body-color">
                    {/* SBK training and tech services is a training and tech
                    service provider. Our company caters various services like
                    online learning, one on one coaching , psychometric testing
                    and corporate trainings to business firms and individuals.
                    We are also specialized in web development. We design
                    customized websites for business firms and individuals. */}
                    At Mind transformative solutions, we believe that success in
                    any endeavor, whether personal or professional, is deeply
                    intertwined with emotional intelligence. In a world that
                    constantly evolves, where challenges are diverse and
                    dynamics ever-changing, the ability to understand and
                    navigate emotions is a cornerstone of triumph.
                  </p>
                  <p class="mb-9 text-base leading-relaxed text-body-color">
                    {/* Our strength lies in our ability to share powerful concepts
                    that help people to get into a learning process and change
                    their lives, build brands, enhance company profile to ensure
                    business development . Our vision is transformation for
                    business firms and individuals. */}
                    <h3 className="font-bold text-dark">Our Mission:</h3>{" "}
                    Elevating Success Through Emotional Intelligence We
                    specialize in providing cutting-edge consultancy services
                    that revolve around emotional intelligence. Our mission is
                    to empower individuals, teams, and organizations to unlock
                    their full potential by harnessing the power of emotions. We
                    understand that success is not only about technical
                    expertise but also about mastering the art of emotional
                    agility.
                  </p>
                  <Link
                    to="/aboutpage"
                    class="inline-flex items-center justify-center rounded bg-primary py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:bg-opacity-90 hover:shadow-lg"
                  >
                    Learn More
                  </Link>
                </div>
                <div class="text-center">
                  <div class="relative z-10 inline-block">
                    <img
                      src={aboutImg3}
                      alt="image"
                      class="mx-auto lg:ml-auto px-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
