import React from "react";
import { Link } from "react-router-dom";
import { inperson, oneOnone, online, training } from "../../assets";

const Feature = () => {
  return (
    <section id="services" className="pt-20  pb-8 lg:pt-[80px] lg:pb-[70px]">
      <div className="container">
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4">
            <div className="mb-12 max-w-[620px] lg:mb-20">
              <span className="mb-2 block text-lg font-semibold text-primary">
                Services
              </span>
              <h2 className="mb-4 text-3xl font-bold text-dark sm:text-4xl md:text-[42px]">
                We are specialized in
              </h2>
              {/* <p className="text-lg leading-relaxed text-body-color sm:text-xl sm:leading-relaxed">
                There are many variations of passages of Lorem Ipsum available
                but the majority have suffered alteration in some form.
              </p> */}
            </div>
          </div>
        </div>
        <div className="-mx-4 flex flex-wrap">
          <div className="w-full px-4 md:w-1/2 lg:w-1/4">
            <div
              className="wow fadeInUp group mb-12 bg-white"
              data-wow-delay=".1s"
            >
              <div className="relative z-10 mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                <span className="absolute top-0 left-0 z-[-1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary duration-300 group-hover:rotate-45"></span>
                <img src={online} alt="" />
              </div>
              <h4 className="mb-3 text-xl font-bold text-dark">
                Online consultancy
              </h4>
              <p className="mb-8 text-body-color lg:mb-11">
                We are available online also.
              </p>
              <Link
                to={"/Online-consultancy"}
                className="text-base font-medium text-body-color hover:text-primary"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/4">
            <div
              className="wow fadeInUp group mb-12 bg-white"
              data-wow-delay=".15s"
            >
              <div className="relative z-10 mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-white">
                <span className="absolute top-0 left-0 z-[-1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary duration-300 group-hover:rotate-45"></span>
                <img src={oneOnone} alt="" />
              </div>
              <h4 className="mb-3 text-xl font-bold text-dark">
                One on one coaching on Emotional Intelligence
              </h4>
              <p className="mb-8 text-body-color lg:mb-11">
                1. One week programme on Emotional Intelligence. <br /> 2. 10
                days programme on Emotional Intelligence for Startup
                Entrepreneurs.
              </p>
              <Link
                to={"/Emotional-Intelligence"}
                className="text-base font-medium text-body-color hover:text-primary"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/4">
            <div
              className="wow fadeInUp group mb-12 bg-white"
              data-wow-delay=".2s"
            >
              <div className="relative z-10 mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-primary">
                <span className="absolute top-0 left-0 z-[-1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary duration-300 group-hover:rotate-45"></span>
                <img src={training} alt="" />
              </div>
              <h4 className="mb-3 text-xl font-bold text-dark">Trainings</h4>
              <p className="mb-8 text-body-color lg:mb-11">
                1. Soft skill Training <br /> 2. Life skill Training
              </p>
              <Link
                to={"/Trainings"}
                className="text-base font-medium text-body-color hover:text-primary"
              >
                Learn More
              </Link>
            </div>
          </div>
          <div className="w-full px-4 md:w-1/2 lg:w-1/4">
            <div
              className="wow fadeInUp group mb-12 bg-white"
              data-wow-delay=".25s"
            >
              <div className="relative z-10 mb-8 flex h-[70px] w-[70px] items-center justify-center rounded-2xl bg-white">
                <span className="absolute top-0 left-0 z-[-1] mb-8 flex h-[70px] w-[70px] rotate-[25deg] items-center justify-center rounded-2xl bg-primary duration-300 group-hover:rotate-45"></span>
                <img src={inperson} alt="" />
              </div>
              <h4 className="mb-3 text-xl font-bold text-dark">
                In Person Consultancy
              </h4>
              <p className="mb-8 text-body-color lg:mb-11">
                We provide personalized guidance and solutions.
              </p>
              <Link
                to={"/Consultancy"}
                className="text-base font-medium text-body-color hover:text-primary"
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
