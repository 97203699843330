import React, { useEffect } from "react";
import { Trainingabout } from "../../../assets";

const Feature3 = () => {
  useEffect(() => {
    // Scroll to the top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  return (
    <section
      id="about"
      class="bg-[#4B0082] pt-20 pb-20 lg:pt-[120px] lg:pb-[120px]"
    >
      <div class="container">
        <div class="wow fadeInUp bg-white" data-wow-delay=".2s">
          <div class="-mx-4 flex flex-wrap">
            <div class="w-full px-4">
              <div class="items-center justify-between overflow-hidden border lg:flex">
                <div class="w-full py-12 px-7 sm:px-12 md:p-16 lg:max-w-[565px] lg:py-9 lg:px-16 xl:max-w-[640px] xl:p-[70px]">
                  <span class="mb-5 inline-block bg-primary py-2 px-5 text-sm font-medium text-white">
                    Services
                  </span>
                  <h2 class="mb-6 text-3xl font-bold text-dark sm:text-4xl sm:leading-snug 2xl:text-[40px]">
                    {/* We have transformed our life and can do the same for you…. */}
                    {/* Welcome to <br />{" "}
                    <span className="text-[#4B0082] ml-2 font-mono  font-extrabold">
                      Mind transformative solutions
                    </span> */}
                    Training on Soft Skill and Life Skill
                  </h2>
                  <p class="mb-9 text-base leading-relaxed text-body-color">
                    "Empower yourself with our comprehensive Soft Skills and
                    Life Skills Training, designed to equip you with the
                    essential tools for personal and professional success. In
                    today's dynamic world, possessing a strong foundation in
                    soft skills and life skills is instrumental in navigating
                    challenges, fostering meaningful relationships, and
                    achieving your goals.
                  </p>
                  <p class="mb-9 text-base leading-relaxed text-body-color">
                    <h3 className="font-bold text-dark">
                      Soft Skills Overview:
                    </h3>{" "}
                    Soft skills encompass a range of interpersonal,
                    communication, and social attributes that enhance your
                    ability to collaborate, lead, and adapt in various
                    environments. Our training program focuses on developing key
                    soft skills
                  </p>
                  <p class="mb-9 text-base leading-relaxed text-body-color">
                    <h3 className="font-bold text-dark">
                      Life Skills Overview:{" "}
                    </h3>{" "}
                    Life skills are the foundation for personal development and
                    well-being. Our training program encompasses a variety of
                    life skills essential for success in both personal and
                    professional realms.
                  </p>
                  <p class="mb-9 text-base leading-relaxed text-body-color">
                    <h3 className="font-bold text-dark">Training Format:</h3> •
                    Interactive Workshops: Engage in hands-on activities and
                    discussions for practical skill application. <br />
                    • Real-life Scenarios: Explore real-world situations to
                    enhance your problem-solving abilities. <br /> •
                    Personalized Coaching: Receive individualized feedback and
                    guidance to maximize your learning. <br />
                    Invest in yourself with our Soft Skills and Life Skills
                    Training, and embark on a journey of self-improvement,
                    empowerment, and success."
                  </p>
                </div>
                <div class="text-center">
                  <div class="relative z-10 inline-block">
                    <img
                      src={Trainingabout}
                      alt="image"
                      class="mx-auto lg:ml-auto px-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature3;
