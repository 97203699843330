import React from "react";
import { video1 } from "../../assets";

const Video = () => {
  return (
    <div className="h-screen flex justify-center items-center  lg:m-20">
      <div className="rounded-lg shadow-lg bg-white max-w-full">
        <video
          width="100%"
          height="100%"
          controls
          className="w-full h-full rounded-t-lg"
        >
          <source src={video1} type="video/mp4" />
          <source src="movie.ogg" type="video/ogg" />
          Your browser does not support the video tag.
        </video>
        <div className="p-6">
          <h5 className="text-gray-900 text-xl font-medium mb-2">
            Post session snap
          </h5>
          {/* <p className="text-gray-700 text-base mb-4">
            Some quick example text to build on the card title and make up the
            bulk of the card's content.
          </p> */}
          {/* <button
            type="button"
            className="inline-block px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
          >
            Button
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Video;
