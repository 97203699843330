import React from "react";
import { ButterflySVG } from "../../assets";
import "./banner.css"; // Import your CSS file

// bg-gradient-to-br from-[#00CED1] via-[#FF1493] to-[#4B0082]
// bg-gradient-to-t from-[#00CED1] via-[#FF1493] to-[#4B0082]

export const Banner = () => {
  return (
    <div
      id="home"
      className="relative overflow-hidden bg-gradient-to-r from-[#00CED1] via-[#FF1493] to-[#4B0082] pt-[120px] md:pt-[130px] lg:pt-[160px] pb-[150px]"
      style={{
        position: "relative",
        overflow: "hidden",
      }}
    >
      <div className="background-pattern"></div>
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "10%",
          transform: "translate(-50%, -50%)",
          width: "25%",
          height: "auto",
          zIndex: 1000,
        }}
      >
        <img
          src={ButterflySVG}
          alt="Butterfly"
          style={{ width: "100%", height: "100%", objectFit: "cover" }}
        />
      </div>
      <div className="container">
        <div className="-mx-4 flex flex-wrap items-center">
          <div className="w-full px-4">
            <div
              className="hero-content wow fadeInUp mx-auto max-w-[780px] text-center"
              data-wow-delay=".2s"
            >
              <h1 className=" pt-12 lg:pt-0 mb-8 text-2xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
                We have Transformed our Life and can do the same for you
              </h1>
              <p className="mx-auto mb-10 max-w-[600px] text-base text-[#ffffff] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
                Our strength lies in our ability to share powerful concepts that
                help people to get into a learning process and change their
                lives. <br />
                Our vision is Transformation for Individuals and Business firms
                .
              </p>
              <ul className="mb-10 flex flex-wrap items-center justify-center">
                <li>
                  <a
                    href="/#services"
                    className="inline-flex items-center justify-center rounded-lg bg-white py-4 px-6 text-center text-base font-medium text-dark transition duration-300 ease-in-out hover:text-primary hover:shadow-lg sm:px-10"
                  >
                    More
                  </a>
                </li>
                <li>
                  <a
                    href="/#services"
                    className="flex items-center py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:opacity-70 sm:px-10"
                  >
                    check this
                    <span className="pl-2">
                      <svg
                        width="20"
                        height="8"
                        viewBox="0 0 20 8"
                        className="fill-current"
                      >
                        <path d="M19.2188 2.90632L17.0625 0.343819C16.875 0.125069 16.5312 0.0938193 16.2812 0.281319C16.0625 0.468819 16.0312 0.812569 16.2188 1.06257L18.25 3.46882H0.9375C0.625 3.46882 0.375 3.71882 0.375 4.03132C0.375 4.34382 0.625 4.59382 0.9375 4.59382H18.25L16.2188 7.00007C16.0312 7.21882 16.0625 7.56257 16.2812 7.78132C16.375 7.87507 16.5 7.90632 16.625 7.90632C16.7812 7.90632 16.9375 7.84382 17.0312 7.71882L19.1875 5.15632C19.75 4.46882 19.75 3.53132 19.2188 2.90632Z" />
                      </svg>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// import React from "react";
// import { ButterflySVG } from "../../assets";
// // import { brand, heroImg } from "../../assets";

// export const Banner = () => {
//   return (
//     <div
//       id="home"
//       className="relative overflow-hidden bg-gradient-to-r from-[#00CED1] via-[#FF1493] to-[#4B0082] pt-[120px] md:pt-[130px] lg:pt-[160px] pb-[120px]"
//     >
//       <div
//         style={{
//           position: "absolute",
//           top: "50%",
//           left: "10%", // Adjust the left position as needed
//           transform: "translate(-50%, -50%)",
//           width: "25%", // Adjust the width as needed
//           height: "auto", // Maintain aspect ratio
//           zIndex: 1000, // Ensure the butterfly is above other elements
//         }}
//       >
//         <img
//           src={ButterflySVG}
//           alt="Butterfly"
//           style={{ width: "100%", height: "100%", objectFit: "cover" }}
//         />
//       </div>

//       <div className="container">
//         <div className="-mx-4 flex flex-wrap items-center">
//           <div className="w-full px-4">
//             <div
//               className="hero-content wow fadeInUp mx-auto max-w-[780px] text-center"
//               data-wow-delay=".2s"
//             >
//               <h1 className="mb-8 text-3xl font-bold leading-snug text-white sm:text-4xl sm:leading-snug md:text-[45px] md:leading-snug">
//                 {/* <span className="text-[#4B0082]">
//                   Mind transformative solutions:
//                 </span> */}
//                 We have transformed our life and can do the same for you
//               </h1>
//               <p className="mx-auto mb-10 max-w-[600px] text-base text-[#ffffff] sm:text-lg sm:leading-relaxed md:text-xl md:leading-relaxed">
//                 Our strength lies in our ability to share powerful concepts that
//                 help people to get into a learning process and change their
//                 lives. <br />
//                 Our vision is transformation for business firms and individuals.
//               </p>
//               <ul className="mb-10 flex flex-wrap items-center justify-center">
//                 <li>
//                   <a
//                     href="/#features"
//                     className="inline-flex items-center justify-center rounded-lg bg-white py-4 px-6 text-center text-base font-medium text-dark transition duration-300 ease-in-out hover:text-primary hover:shadow-lg sm:px-10"
//                   >
//                     More
//                   </a>
//                 </li>
//                 <li>
//                   <a
//                     href="/#features"
//                     className="flex items-center py-4 px-6 text-base font-medium text-white transition duration-300 ease-in-out hover:opacity-70 sm:px-10"
//                   >
//                     check this
//                     <span className="pl-2">
//                       <svg
//                         width="20"
//                         height="8"
//                         viewBox="0 0 20 8"
//                         className="fill-current"
//                       >
//                         <path d="M19.2188 2.90632L17.0625 0.343819C16.875 0.125069 16.5312 0.0938193 16.2812 0.281319C16.0625 0.468819 16.0312 0.812569 16.2188 1.06257L18.25 3.46882H0.9375C0.625 3.46882 0.375 3.71882 0.375 4.03132C0.375 4.34382 0.625 4.59382 0.9375 4.59382H18.25L16.2188 7.00007C16.0312 7.21882 16.0625 7.56257 16.2812 7.78132C16.375 7.87507 16.5 7.90632 16.625 7.90632C16.7812 7.90632 16.9375 7.84382 17.0312 7.71882L19.1875 5.15632C19.75 4.46882 19.75 3.53132 19.2188 2.90632Z" />
//                       </svg>
//                     </span>
//                   </a>
//                 </li>
//               </ul>
//               {/* <div className="wow fadeInUp text-center" data-wow-delay=".3s">
//                 <img
//                   src={brand}
//                   alt="image"
//                   className="mx-auto w-full max-w-[250px] opacity-50 transition duration-300 ease-in-out hover:opacity-100"
//                 />
//               </div> */}
//             </div>
//           </div>

//           {/* <div className="w-full px-4">
//             <div
//               className="wow fadeInUp relative z-10 mx-auto max-w-[845px]"
//               data-wow-delay=".25s"
//             >
//               <div className="mt-16">
//                 <img
//                   src={heroImg}
//                   alt="hero"
//                   className="mx-auto max-w-full rounded-t-xl rounded-tr-xl"
//                 />
//               </div>
//               <div className="absolute bottom-0 -left-9 z-[-1]"></div>
//               <div className="absolute -top-6 -right-6 z-[-1]"></div>
//             </div>
//           </div> */}
//         </div>
//       </div>
//     </div>
//   );
// };
