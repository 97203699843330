import React, { useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";
import { g1, g2, g3, g4, g5, g6 } from "../assets";

const Gallery = () => {
  const slides = [
    {
      url: g1,
    },
    {
      url: g2,
    },
    {
      url: g3,
    },

    {
      url: g4,
    },
    {
      url: g5,
    },
    {
      url: g6,
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };
  return (
    <div className="max-w-[1400px] h-[780px] w-full mx-auto mt-10 py-16 px-4 relative group">
      <div
        style={{ backgroundImage: `url(${slides[currentIndex].url})` }}
        className="w-full h-full rounded-2xl bg-center bg-cover duration-500"
      ></div>
      {/* Left Arrow */}
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>
      {/* Right Arrow */}
      <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>
      <div className="flex top-4 justify-center py-2">
        {slides.map((slide, slideIndex) => (
          <div
            key={slideIndex}
            onClick={() => goToSlide(slideIndex)}
            className="text-2xl cursor-pointer"
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;

// import React, { useState } from "react";
// import Slider from "react-slick";
// import {
//   bannerImgOne,
//   bannerImgTwo,
//   bannerImgThree,
//   bannerImgFour,
//   bannerImgFive,
// } from "../../assets/index";

// const Banner = () => {
//   const [dotActive, setDocActive] = useState(0);
//   const settings = {
//     dots: true,
//     infinite: true,
//     autoplay: true,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     arrows: false,
//     beforeChange: (prev, next) => {
//       setDocActive(next);
//     },
//     appendDots: (dots) => (
//       <div
//         style={{
//           position: "absolute",
//           top: "70%",
//           left: "50%",
//           transform: "translate(-50%, 0)",
//           width: "210px",
//         }}
//       >
//         <ul
//           style={{
//             width: "100%",
//             display: "flex",
//             alignItems: "center",
//             justifyContent: "space-between",
//           }}
//         >
//           {dots}
//         </ul>
//       </div>
//     ),
//     customPaging: (i) => (
//       <div
//         style={
//           i === dotActive
//             ? {
//                 width: "30px",
//                 height: "30px",
//                 borderRadius: "50%",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 color: "white",
//                 background: "#131921",
//                 padding: "8px 0",
//                 cursor: "pointer",
//                 border: "1px solid #f3a847",
//               }
//             : {
//                 width: "30px",
//                 height: "30px",
//                 borderRadius: "50%",
//                 display: "flex",
//                 alignItems: "center",
//                 justifyContent: "center",
//                 background: "#232F3E",
//                 color: "white",
//                 padding: "8px 0",
//                 cursor: "pointer",
//                 border: "1px solid white",
//               }
//         }
//       >
//         {i + 1}
//       </div>
//     ),
//     responsive: [
//       {
//         breakpoint: 500,
//         settings: {
//           dots: true,
//           appendDots: (dots) => (
//             <div
//               style={{
//                 position: "absolute",
//                 top: "60%",
//                 left: "50%",
//                 transform: "translate(-50%, 0)",
//                 width: "150px",
//               }}
//             >
//               <ul
//                 style={{
//                   width: "100%",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "space-between",
//                   fontSize: "12px",
//                 }}
//               >
//                 {" "}
//                 {dots}{" "}
//               </ul>
//             </div>
//           ),
//           customPaging: (i) => (
//             <div
//               style={
//                 i === dotActive
//                   ? {
//                       width: "25px",
//                       height: "25px",
//                       borderRadius: "50%",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       color: "white",
//                       background: "#131921",
//                       padding: "8px 0",
//                       cursor: "pointer",
//                       border: "1px solid #f3a847",
//                     }
//                   : {
//                       width: "25px",
//                       height: "25px",
//                       borderRadius: "50%",
//                       display: "flex",
//                       alignItems: "center",
//                       justifyContent: "center",
//                       background: "#232F3E",
//                       color: "white",
//                       padding: "8px 0",
//                       cursor: "pointer",
//                       border: "1px solid white",
//                     }
//               }
//             >
//               0{i + 1}
//             </div>
//           ),
//         },
//       },
//     ],
//   };
//   return (
//     <div className="w-full">
//       <div className="w-full h-full relative">
//         <Slider {...settings}>
//           <div>
//             <img src={bannerImgOne} alt="bannerImgOne" />
//           </div>
//           <div>
//             <img src={bannerImgTwo} alt="bannerImgTwo" />
//           </div>
//           <div>
//             <img src={bannerImgThree} alt="bannerImgThree" />
//           </div>
//           <div>
//             <img src={bannerImgFour} alt="bannerImgFour" />
//           </div>
//           <div>
//             <img src={bannerImgFive} alt="bannerImgFive" />
//           </div>
//         </Slider>
//       </div>
//     </div>
//   );
// };

// export default Banner;
