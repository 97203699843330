import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import Signin from "./pages/Signin";
import Signup from "./pages/Signup";
import "./index.css";
import AboutPage from "./pages/AboutPage";
import Feature2 from "./components/Feature/pages/Feature2";
import Feature1 from "./components/Feature/pages/Feature1";
import Feature3 from "./components/Feature/pages/Feature3";
import Feature4 from "./components/Feature/pages/Feature4";

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Signup />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/aboutpage" element={<AboutPage />} />
          <Route path="/Online-consultancy" element={<Feature1 />} />
          <Route path="/Emotional-Intelligence" element={<Feature2 />} />
          <Route path="/Trainings" element={<Feature3 />} />
          <Route path="/Consultancy" element={<Feature4 />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

// import React from "react";
// import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import Footer from "./components/Footer/Footer";
// import Header from "./components/Header/Header";
// import Gallery from "./pages/Gallery";
// import Home from "./pages/Home";
// import Signin from "./pages/Signin";
// import Signup from "./pages/Signup";
// import "./index.css";
// import AboutPage from "./pages/AboutPage";
// import Feature2 from "./components/Feature/pages/Feature2";
// import Feature1 from "./components/Feature/pages/Feature1";
// import Feature3 from "./components/Feature/pages/Feature3";
// import Feature4 from "./components/Feature/pages/Feature4";
// // import AdminDashboard from "./pages/AdminDashboard";

// function App() {
//   // const [showButterfly, setShowButterfly] = useState(true);

//   // useEffect(() => {
//   //   // Hide the butterfly after 3 seconds
//   //   const timeoutId = setTimeout(() => {
//   //     setShowButterfly(false);
//   //   }, 3000);

//   //   // Clean up the timeout to avoid memory leaks
//   //   return () => clearTimeout(timeoutId);
//   // }, []); // Empty dependency array to run the effect only once on mount

//   return (
//     // <div style={{ position: "relative" }}>
//     //   <Router>
//     //     <Header />
//     //     {showButterfly && (
//     //       <div
//     //         style={{
//     //           position: "fixed",
//     //           top: "50%",
//     //           left: "10%", // Adjust the left position as needed
//     //           transform: "translate(-50%, -50%)",
//     //           width: "25%", // Adjust the width as needed
//     //           height: "auto", // Maintain aspect ratio
//     //           zIndex: 1000, // Ensure the butterfly is above other elements
//     //         }}
//     //       >
//     //         <img
//     //           src={ButterflySVG}
//     //           alt="Butterfly"
//     //           style={{ width: "100%", height: "100%", objectFit: "cover" }}
//     //         />
//     //       </div>
//     //     )}
//     //     <Routes>
//     //       <Route path="/" element={<Home />} />
//     //       <Route path="/signup" element={<Signup />} />
//     //       <Route path="/signin" element={<Signin />} />
//     //       <Route path="/gallery" element={<Gallery />} />
//     //     </Routes>
//     //     <Footer />
//     //   </Router>
//     // </div>
//     <div>
//       <Router>
//         <Header />
//         <Routes>
//           <Route path="/" element={<Home />} />
//           <Route path="/register" element={<Signup />} />
//           <Route path="/signin" element={<Signin />} />
//           <Route path="/gallery" element={<Gallery />} />
//           <Route path="/aboutpage" element={<AboutPage />} />
//           <Route path="/featurepage1" element={<Feature1 />} />
//           <Route path="/featurepage2" element={<Feature2 />} />
//           <Route path="/featurepage3" element={<Feature3 />} />
//           <Route path="/featurepage4" element={<Feature4 />} />
//           {/* <Route path="/admin" element={<AdminDashboard />} /> */}
//         </Routes>
//         <Footer />
//       </Router>
//     </div>
//   );
// }

// export default App;
