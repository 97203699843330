import React from "react";
import { Banner } from "../components/Banner/Banner";
import Feature from "../components/Feature/Feature";
import About from "../components/About/About";
// import Pricing from "../components/Pricing/Pricing";
import Faq from "../components/FAQ/Faq";
import Testimonial from "../components/Testimonial/Testimonial";
import Team from "../components/Team/Team";
import Contact from "../components/Contact/Contact";
import About1 from "../components/About/About1";
import Video from "../components/video/Video";
import SubscribeForm from "../components/Newsletter/SubscribeForm";
// import Gallery from "./Gallery";

const Home = () => {
  return (
    <div>
      {" "}
      <Banner />
      {/* <Gallery /> */}
      <About />
      <Feature />
      <About1 />
      <Video />
      {/* <SubscribeForm /> */}
      {/* <Pricing /> */}
      <Faq />
      <Testimonial />
      <Team />
      <Contact />
    </div>
  );
};

export default Home;
