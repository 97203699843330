import React, { useState, useEffect, useRef } from "react";
import { logo1 } from "../../assets";
import { Link } from "react-router-dom";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  // const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  // const [isFaqOpen, setIsFaqOpen] = useState(false);

  const handleScroll = () => {
    // Access the header element using the ref
    const udHeader = udHeaderRef.current;
    // Check if the element exists before accessing its properties
    if (udHeader) {
      const sticky = udHeader.offsetTop;
      setIsSticky(window.pageYOffset > sticky);
      const backToTop = document.querySelector(".back-to-top");
      if (backToTop) {
        backToTop.style.display = window.pageYOffset > 50 ? "flex" : "none";
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  // const toggleSubMenu = () => {
  //   setIsSubMenuOpen(!isSubMenuOpen);
  // };

  // const toggleFaq = () => {
  //   setIsFaqOpen(!isFaqOpen);
  // };

  // Create a ref for the header element
  const udHeaderRef = useRef(null);

  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };

  // const [isSticky, setIsSticky] = useState(false);
  // const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  // const handleScroll = () => {
  //   // Access the header element using the ref
  //   const udHeader = udHeaderRef.current;

  //   // Check if the element exists before accessing its properties
  //   if (udHeader) {
  //     const sticky = udHeader.offsetTop;
  //     setIsSticky(window.pageYOffset > sticky);

  //     const backToTop = document.querySelector(".back-to-top");
  //     if (backToTop) {
  //       backToTop.style.display = window.pageYOffset > 50 ? "flex" : "none";
  //     }
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const toggleNavbar = () => {
  //   setIsNavbarOpen(!isNavbarOpen);
  // };

  // const closeNavbar = () => {
  //   setIsNavbarOpen(false);
  // };

  // // Create a ref for the header element
  // const udHeaderRef = useRef(null);

  return (
    <div
      ref={udHeaderRef} // Attach the ref to the header element
      className={`ud-header absolute top-0 left-0 z-40 flex w-full items-center hello bg-white bg-opacity-80 ${
        isSticky ? "sticky" : ""
      }`}
    >
      <div className="container">
        <div className="relative -mx-4 flex items-center justify-between">
          <div className="w-60 max-w-full px-4">
            <Link to={"/"} className="navbar-logo block w-full py-5">
              <div className="flex items-center pr-36">
                {/* Container for logo and text */}

                <img src={logo1} alt="logo" className="header-logo w-18 h-20" />

                <p className="text-[#4B0082] ml-2 text-[16px] lg:text-xl font-extrabold lg:whitespace-nowrap flex flex-col">
                  Mind Transformative Solutions
                  <span className=" whitespace-nowrap text-[9px] lg:text-[10px] italic">
                    Empowering change, unleashing potential.
                  </span>
                </p>
              </div>
            </Link>
          </div>

          <div className="flex w-full items-center justify-between px-4">
            <div>
              <button
                id="navbarToggler"
                className={`absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden ${
                  isNavbarOpen ? "navbarTogglerActive" : ""
                }`}
                onClick={toggleNavbar}
              >
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
                <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
              </button>
              <nav
                id="navbarCollapse"
                className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:py-0 lg:px-4 lg:shadow-none xl:px-6 ${
                  isNavbarOpen ? "" : "hidden"
                }`}
              >
                <ul className="blcok lg:flex lg:ml-40">
                  <li className="group relative">
                    <a
                      href="/#home"
                      onClick={closeNavbar}
                      className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70"
                    >
                      Home
                    </a>
                  </li>

                  <li className="group relative">
                    <a
                      href="/#about"
                      onClick={closeNavbar}
                      className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12"
                    >
                      About
                    </a>
                  </li>
                  <li className="group relative">
                    <a
                      href="/#services"
                      onClick={closeNavbar}
                      className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12"
                    >
                      Services
                    </a>
                  </li>
                  <li className="group relative">
                    <a
                      href="/#team"
                      onClick={closeNavbar}
                      className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12"
                    >
                      Team
                    </a>
                  </li>
                  <li className="group relative">
                    <a
                      href="/#contact"
                      onClick={closeNavbar}
                      className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12"
                    >
                      Contact
                    </a>
                  </li>
                  {/* <li className="submenu-item group relative">
                    <a
                      onClick={toggleSubMenu}
                      className="relative mx-8 flex py-2 text-base text-dark after:absolute after:right-1 after:top-1/2 after:mt-[-2px] after:h-2 after:w-2 after:-translate-y-1/2 after:rotate-45 after:border-b-2 after:border-r-2 after:border-current group-hover:text-primary lg:mr-0 lg:ml-8 lg:inline-flex lg:py-6 lg:pl-0 lg:pr-4 lg:text-white lg:after:right-0 lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12 cursor-pointer"
                    >
                      pages
                    </a>
                    <div
                      className={`submenu relative top-full left-0 w-[250px] rounded-sm bg-white p-4 transition-[top] duration-300 group-hover:opacity-100 lg:invisible lg:absolute lg:top-[110%] lg:block lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full ${
                        isSubMenuOpen ? "" : "hidden"
                      }`}
                    >
                      <Link
                        to={"/aboutpage"}
                        className="block cursor-pointer rounded py-[10px] px-4 text-sm text-body-color hover:text-primary"
                      >
                        About Page
                      </Link>
                      <a className="block cursor-pointer rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
                        Pricing Page
                      </a>
                      <a className="block cursor-pointer rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
                        Contact Page
                      </a>
                      <a className="block cursor-pointer rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
                        Blog Grid Page
                      </a>
                      <a className="block cursor-pointer rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
                        Blog Details Page
                      </a>
                      <a className="block cursor-pointer rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
                        Sign Up Page
                      </a>
                      <Link
                        to={"/register"}
                        className="block cursor-pointer rounded py-[10px] px-4 text-sm text-body-color hover:text-primary"
                      >
                        Register
                      </Link>
                      <a className="block cursor-pointer rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
                        404 Page
                      </a>
                    </div>
                  </li> */}
                  {/* Add more navigation items here */}
                  <li className="group relative lg:hidden">
                    {/* <Link
                      to="/signin"
                      onClick={closeNavbar}
                      className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12"
                    >
                      Signin
                    </Link> */}
                  </li>
                  <li className="group relative lg:hidden">
                    <Link
                      to="/register"
                      onClick={closeNavbar}
                      className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12"
                    >
                      Register
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
              {/* <Link
                to="/signin"
                className="loginBtn cursor-pointer py-3 px-7 text-base font-medium text-white hover:opacity-70"
              >
                Sign In
              </Link> */}
              <Link
                to="/register"
                className="signUpBtn cursor-pointer rounded-lg bg-white bg-opacity-20 py-3 px-6 text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-100 hover:text-dark"
              >
                Register
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

// import React, { useState, useEffect, useRef } from "react";
// import "../../assets/css/animate.css";
// import { logo } from "../../assets";
// import { Link } from "react-scroll"; // Import the Link component from react-scroll

// const Header = () => {
//   const [isSticky, setIsSticky] = useState(false);
//   const [isNavbarOpen, setIsNavbarOpen] = useState(false);
//   const [activeSection, setActiveSection] = useState("");

//   const handleScroll = () => {
//     const udHeader = udHeaderRef.current;

//     if (udHeader) {
//       const sticky = udHeader.offsetTop;
//       setIsSticky(window.pageYOffset > sticky);

//       const backToTop = document.querySelector(".back-to-top");
//       if (backToTop) {
//         backToTop.style.display = window.pageYOffset > 50 ? "flex" : "none";
//       }

//       const sections = document.querySelectorAll(".ud-menu-scroll");
//       const scrollPos =
//         window.pageYOffset ||
//         document.documentElement.scrollTop ||
//         document.body.scrollTop;

//       for (let i = 0; i < sections.length; i++) {
//         const currLink = sections[i];
//         const val = currLink.getAttribute("href");
//         const refElement = document.querySelector(val);
//         const scrollTopMinus = scrollPos + 73;
//         if (
//           refElement.offsetTop <= scrollTopMinus &&
//           refElement.offsetTop + refElement.offsetHeight > scrollTopMinus
//         ) {
//           setActiveSection(val);
//         }
//       }
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const toggleNavbar = () => {
//     setIsNavbarOpen(!isNavbarOpen);
//   };

//   const closeNavbar = () => {
//     setIsNavbarOpen(false);
//   };

//   const udHeaderRef = useRef(null);

//   return (
//     <div
//       ref={udHeaderRef}
//       className={`ud-header absolute top-0 left-0 z-40 flex w-full items-center bg-transparent ${
//         isSticky ? "sticky" : ""
//       }`}
//     >
//       <div className="container">
//         <div className="relative -mx-4 flex items-center justify-between">
//           <div className="w-60 max-w-full px-4">
//             <a className="navbar-logo block w-full py-5">
//               <img src={logo} alt="logo" className="header-logo w-full" />
//             </a>
//           </div>
//           <div className="flex w-full items-center justify-between px-4">
//             <div>
//               <button
//                 id="navbarToggler"
//                 className={`absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden ${
//                   isNavbarOpen ? "navbarTogglerActive" : ""
//                 }`}
//                 onClick={toggleNavbar}
//               >
//                 <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
//                 <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
//                 <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
//               </button>
//               <nav
//                 id="navbarCollapse"
//                 className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:py-0 lg:px-4 lg:shadow-none xl:px-6 ${
//                   isNavbarOpen ? "" : "hidden"
//                 }`}
//               >
//                 <ul className="blcok lg:flex">
//                   <li className="group relative">
//                     <Link // Use the Link component from react-scroll
//                       to="home" // Replace with your section ID
//                       spy={true}
//                       smooth={true}
//                       duration={500}
//                       offset={-60} // Adjust this value to match your header's height
//                       className={`ud-menu-scroll mx-8 flex py-2 text-base text-dark ${
//                         activeSection === "#home" ? "active" : ""
//                       }`}
//                     >
//                       Home
//                     </Link>
//                   </li>
//                   <li className="group relative">
//                     <Link
//                       to="about" // Replace with your section ID
//                       spy={true}
//                       smooth={true}
//                       duration={500}
//                       offset={-60} // Adjust this value to match your header's height
//                       className={`ud-menu-scroll mx-8 flex py-2 text-base text-dark ${
//                         activeSection === "#about" ? "active" : ""
//                       }`}
//                     >
//                       About
//                     </Link>
//                   </li>
//                   {/* Add more navigation items here */}
//                 </ul>
//               </nav>
//             </div>
//             <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
//               <a className="loginBtn py-3 px-7 text-base font-medium text-white hover:opacity-70">
//                 Sign In
//               </a>
//               <a className="signUpBtn rounded-lg bg-white bg-opacity-20 py-3 px-6 text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-100 hover:text-dark">
//                 Sign Up
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Header;

// import React, { useState, useEffect, useRef } from "react";
// import "../../assets/css/animate.css";
// import { logo } from "../../assets";

// const Header = () => {
//   const [isSticky, setIsSticky] = useState(false);
//   const [isNavbarOpen, setIsNavbarOpen] = useState(false);
//   const [activeSection, setActiveSection] = useState("");

//   const handleScroll = () => {
//     const udHeader = udHeaderRef.current;

//     if (udHeader) {
//       const sticky = udHeader.offsetTop;
//       setIsSticky(window.pageYOffset > sticky);

//       const backToTop = document.querySelector(".back-to-top");
//       if (backToTop) {
//         backToTop.style.display = window.pageYOffset > 50 ? "flex" : "none";
//       }

//       const sections = document.querySelectorAll(".ud-menu-scroll");
//       const scrollPos =
//         window.pageYOffset ||
//         document.documentElement.scrollTop ||
//         document.body.scrollTop;

//       for (let i = 0; i < sections.length; i++) {
//         const currLink = sections[i];
//         const val = currLink.getAttribute("href");
//         const refElement = document.querySelector(val);
//         const scrollTopMinus = scrollPos + 73;
//         if (
//           refElement.offsetTop <= scrollTopMinus &&
//           refElement.offsetTop + refElement.offsetHeight > scrollTopMinus
//         ) {
//           setActiveSection(val);
//         }
//       }
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const toggleNavbar = () => {
//     setIsNavbarOpen(!isNavbarOpen);
//   };

//   const closeNavbar = () => {
//     setIsNavbarOpen(false);
//   };

//   const udHeaderRef = useRef(null);

//   return (
//     <div
//       ref={udHeaderRef}
//       className={`ud-header absolute top-0 left-0 z-40 flex w-full items-center bg-transparent ${
//         isSticky ? "sticky" : ""
//       }`}
//     >
//       <div className="container">
//         <div className="relative -mx-4 flex items-center justify-between">
//           <div className="w-60 max-w-full px-4">
//             <a className="navbar-logo block w-full py-5">
//               <img src={logo} alt="logo" className="header-logo w-full" />
//             </a>
//           </div>
//           <div className="flex w-full items-center justify-between px-4">
//             <div>
//               <button
//                 id="navbarToggler"
//                 className={`absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden ${
//                   isNavbarOpen ? "navbarTogglerActive" : ""
//                 }`}
//                 onClick={toggleNavbar}
//               >
//                 <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
//                 <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
//                 <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
//               </button>
//               <nav
//                 id="navbarCollapse"
//                 className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:py-0 lg:px-4 lg:shadow-none xl:px-6 ${
//                   isNavbarOpen ? "" : "hidden"
//                 }`}
//               >
//                 <ul className="blcok lg:flex">
//                   <li className="group relative">
//                     <a
//                       className={`ud-menu-scroll mx-8 flex py-2 text-base text-dark ${
//                         activeSection === "#home" ? "active" : ""
//                       }`}
//                       href="#home"
//                     >
//                       Home
//                     </a>
//                   </li>
//                   <li className="group relative">
//                     <a
//                       className={`ud-menu-scroll mx-8 flex py-2 text-base text-dark ${
//                         activeSection === "#about" ? "active" : ""
//                       }`}
//                       href="#about"
//                     >
//                       About
//                     </a>
//                   </li>
//                   {/* Add more navigation items here */}
//                 </ul>
//               </nav>
//             </div>
//             <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
//               <a className="loginBtn py-3 px-7 text-base font-medium text-white hover:opacity-70">
//                 Sign In
//               </a>
//               <a className="signUpBtn rounded-lg bg-white bg-opacity-20 py-3 px-6 text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-100 hover:text-dark">
//                 Sign Up
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Header;

// import React, { useState, useEffect, useRef } from "react";
// import "../../assets/css/animate.css";
// import { logo } from "../../assets";

// const Header = () => {
//   const [isSticky, setIsSticky] = useState(false);
//   const [isNavbarOpen, setIsNavbarOpen] = useState(false);

//   const handleScroll = () => {
//     // Access the header element using the ref
//     const udHeader = udHeaderRef.current;

//     // Check if the element exists before accessing its properties
//     if (udHeader) {
//       const sticky = udHeader.offsetTop;
//       setIsSticky(window.pageYOffset > sticky);

//       const backToTop = document.querySelector(".back-to-top");
//       if (backToTop) {
//         backToTop.style.display = window.pageYOffset > 50 ? "flex" : "none";
//       }
//     }
//   };

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   const toggleNavbar = () => {
//     setIsNavbarOpen(!isNavbarOpen);
//   };

//   const closeNavbar = () => {
//     setIsNavbarOpen(false);
//   };

//   // Create a ref for the header element
//   const udHeaderRef = useRef(null);

//   return (
//     <div
//       ref={udHeaderRef} // Attach the ref to the header element
//       className={`ud-header absolute top-0 left-0 z-40 flex w-full items-center bg-transparent ${
//         isSticky ? "sticky" : ""
//       }`}
//     >
//       <div className="container">
//         <div className="relative -mx-4 flex items-center justify-between">
//           <div className="w-60 max-w-full px-4">
//             <a className="navbar-logo block w-full py-5">
//               <img src={logo} alt="logo" className="header-logo w-full" />
//             </a>
//           </div>
//           <div className="flex w-full items-center justify-between px-4">
//             <div>
//               <button
//                 id="navbarToggler"
//                 className={`absolute right-4 top-1/2 block -translate-y-1/2 rounded-lg px-3 py-[6px] ring-primary focus:ring-2 lg:hidden ${
//                   isNavbarOpen ? "navbarTogglerActive" : ""
//                 }`}
//                 onClick={toggleNavbar}
//               >
//                 <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
//                 <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
//                 <span className="relative my-[6px] block h-[2px] w-[30px] bg-white"></span>
//               </button>
//               <nav
//                 id="navbarCollapse"
//                 className={`absolute right-4 top-full w-full max-w-[250px] rounded-lg bg-white py-5 shadow-lg lg:static lg:block lg:w-full lg:max-w-full lg:bg-transparent lg:py-0 lg:px-4 lg:shadow-none xl:px-6 ${
//                   isNavbarOpen ? "" : "hidden"
//                 }`}
//               >
//                 <ul className="blcok lg:flex">
//                   <li className="group relative">
//                     <a className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70">
//                       Home
//                     </a>
//                   </li>
//                   <li className="group relative">
//                     <a className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12">
//                       About
//                     </a>
//                   </li>
//                   <li className="group relative">
//                     <a className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12">
//                       Pricing
//                     </a>
//                   </li>
//                   <li className="group relative">
//                     <a className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12">
//                       Team
//                     </a>
//                   </li>
//                   <li className="group relative">
//                     <a className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12">
//                       Contact
//                     </a>
//                   </li>
//                   <li className="submenu-item group relative">
//                     <a className="relative mx-8 flex py-2 text-base text-dark after:absolute after:right-1 after:top-1/2 after:mt-[-2px] after:h-2 after:w-2 after:-translate-y-1/2 after:rotate-45 after:border-b-2 after:border-r-2 after:border-current group-hover:text-primary lg:mr-0 lg:ml-8 lg:inline-flex lg:py-6 lg:pl-0 lg:pr-4 lg:text-white lg:after:right-0 lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12">
//                       pages
//                     </a>
//                     <div className="submenu relative top-full left-0 hidden w-[250px] rounded-sm bg-white p-4 transition-[top] duration-300 group-hover:opacity-100 lg:invisible lg:absolute lg:top-[110%] lg:block lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full">
//                       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//                         About Page
//                       </a>
//                       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//                         Pricing Page
//                       </a>
//                       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//                         Contact Page
//                       </a>
//                       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//                         Blog Grid Page
//                       </a>
//                       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//                         Blog Details Page
//                       </a>
//                       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//                         Sign Up Page
//                       </a>
//                       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//                         Sign In Page
//                       </a>
//                       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//                         404 Page
//                       </a>
//                     </div>
//                   </li>
//                   {/* Add more navigation items here */}
//                 </ul>
//               </nav>
//             </div>
//             <div className="hidden justify-end pr-16 sm:flex lg:pr-0">
//               <a className="loginBtn py-3 px-7 text-base font-medium text-white hover:opacity-70">
//                 Sign In
//               </a>
//               <a className="signUpBtn rounded-lg bg-white bg-opacity-20 py-3 px-6 text-base font-medium text-white duration-300 ease-in-out hover:bg-opacity-100 hover:text-dark">
//                 Sign Up
//               </a>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Header;

// {
//   /* <ul className="blcok lg:flex">
//   <li className="group relative">
//     <a className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70">
//       Home
//     </a>
//   </li>
//   <li className="group relative">
//     <a className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12">
//       About
//     </a>
//   </li>
//   <li className="group relative">
//     <a className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12">
//       Pricing
//     </a>
//   </li>
//   <li className="group relative">
//     <a className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12">
//       Team
//     </a>
//   </li>
//   <li className="group relative">
//     <a className="ud-menu-scroll mx-8 flex py-2 text-base text-dark group-hover:text-primary lg:mr-0 lg:ml-7 lg:inline-flex lg:py-6 lg:px-0 lg:text-white lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12">
//       Contact
//     </a>
//   </li>
//   <li className="submenu-item group relative">
//     <a className="relative mx-8 flex py-2 text-base text-dark after:absolute after:right-1 after:top-1/2 after:mt-[-2px] after:h-2 after:w-2 after:-translate-y-1/2 after:rotate-45 after:border-b-2 after:border-r-2 after:border-current group-hover:text-primary lg:mr-0 lg:ml-8 lg:inline-flex lg:py-6 lg:pl-0 lg:pr-4 lg:text-white lg:after:right-0 lg:group-hover:text-white lg:group-hover:opacity-70 xl:ml-12">
//       Pages
//     </a>
//     <div className="submenu relative top-full left-0 hidden w-[250px] rounded-sm bg-white p-4 transition-[top] duration-300 group-hover:opacity-100 lg:invisible lg:absolute lg:top-[110%] lg:block lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full">
//       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//         About Page
//       </a>
//       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//         Pricing Page
//       </a>
//       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//         Contact Page
//       </a>
//       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//         Blog Grid Page
//       </a>
//       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//         Blog Details Page
//       </a>
//       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//         Sign Up Page
//       </a>
//       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//         Sign In Page
//       </a>
//       <a className="block rounded py-[10px] px-4 text-sm text-body-color hover:text-primary">
//         404 Page
//       </a>
//     </div>
//   </li>
// </ul>; */
// }
